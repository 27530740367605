/* Main App Container */
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
  color: #ffffff;
  padding: 20px; 
}

/* Background Overlay */
.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364);
  animation: backgroundAnimation 12s ease-in-out infinite;
  z-index: -2;
}

@keyframes backgroundAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Logo */
.logo {
  width: 460px;
  height: 460px;
  animation: float 4s ease-in-out infinite;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.15));
  transition: transform 0.3s;
}
.logo:hover {
  transform: scale(1.1);
  filter: drop-shadow(0 0 10px #1893f8);
}

/* Responsive Logo */
@media (max-width: 480px) {
  .logo {
    width: 220px;
    height: 220px;
  }
}

/* Rotating Tagline */
.tagline {
  font-size: 2.2rem;
  font-weight: 500;
  color: #ffffff;
  opacity: 0.9;
  margin-bottom: 20px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

@media (max-width: 480px) {
  .tagline {
    font-size: 1.5rem;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Loading Bar */
.loading-bar-container {
  width: 220px;
  height: 12px;
  background: linear-gradient(135deg, #ccc, #bbb);
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}
.loading-bar {
  width: 100%;
  height: 100%;
  position: relative;
}
.loading-progress {
  height: 100%;
  background: linear-gradient(to right, #1893f8, #0056b3);
  border-radius: 8px;
  position: absolute;
  animation: loadingAnimation 8s ease-in-out infinite;
}

@keyframes loadingAnimation {
  0% { width: 0%; }
  25% { width: 40%; }
  50% { width: 60%; }
  75% { width: 80%; }
  100% { width: 100%; }
}

/* Responsive Loading Bar */
@media (max-width: 480px) {
  .loading-bar-container {
    width: 180px;
  }
}

/* Coming Soon Text */
.coming-soon-text {
  font-size: 1.7rem;
  font-weight: bold;
  color: #ffffff;
  margin-top: 20px;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  animation: pulse 1.5s infinite, glow 2s alternate infinite ease-in-out;
  transition: text-shadow 0.3s;
}
.coming-soon-text:hover {
  text-shadow: 0 0 20px #0c0c0c, 0 0 30px #121211; 
}

/* Pulse Animation for Coming Soon Text */
@keyframes pulse {
  0%, 100% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.05); opacity: 0.8; }
}

/* Glow Effect */
@keyframes glow {
  from { text-shadow: 0 0 10px #040404, 0 0 20px #090909; }
  to { text-shadow: 0 0 20px #070707, 0 0 30px #0b0b0b; }
}

/* Responsive Text Styles */
@media (max-width: 480px) {
  .work-in-progress {
    font-size: 1.1rem;
  }
  .coming-soon-text {
    font-size: 1.9rem;
  }
}